<template>
  <a-card :bordered="false" class="card-area">
    <div>
      <!-- 表格区域 -->
      <a-table :columns="columns"
               :dataSource="dataSource"
               :loading="loading"
               rowKey="key"
               bordered
               size="small"
               :scroll="{ x: 900 }"
               :pagination="false">
        <template slot="index" slot-scope="text,row,index">
          {{ index + 1 }}
        </template>
      </a-table>
    </div>
  </a-card>
</template>

<script>

export default {
  name: 'SystemProperties',
  data () {
    return {
      dataSource: [],
      loading: false,
    }
  },
  computed: {
    columns () {
      return [{
        title: '序号',
        width: 80,
        align: 'center',
        scopedSlots: {customRender: 'index'}
      }, {
        title: '系统属性',
        dataIndex: 'key',
      },
        {
          title: '配置项值',
          dataIndex: 'value',
          width: 800,
        },
        {
          title: '',
          align: 'center',
        }]
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch (params = {}) {
      this.loading = true
      this.$get('sysConfig/sysProperties').then((r) => {
        this.loading = false
        const list = r.data || []
        this.dataSource = list
      })
    }
  }
}
</script>
